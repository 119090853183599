import React, {useContext, useEffect} from "react";
import {Grid} from 'semantic-ui-react'
import config from "../config";
import axios from "axios";
import {IngestDocContext} from "../context/IngestDocument";
import {AuthContext} from "../context/AuthContext";


export const AssetDetails = () => {
    const {auth, setAuth} = useContext(AuthContext);
    const {ingest, setIngest} = useContext(IngestDocContext)

    function NonEditableField(name: string, value: string) {
        return <Grid.Row>
            <Grid.Column width={3}> <label>{ name } :</label> </Grid.Column>
            <Grid.Column width={10}> <label style={ {color: "#888888"} }>{ value }</label> </Grid.Column>
        </Grid.Row>
    }

    function updateA5IngestData(a5IngestData: any) {
        const videoFormat = a5IngestData ? a5IngestData['asset']['revision']['specDbDocID'] : ""
        const closedCaptionStatus = a5IngestData ? a5IngestData['asset']['closedCaptionStatus']: "No"
        setIngest({...ingest, a5IngestData: a5IngestData, videoFormat, closedCaptionStatus})
        ingest.a5IngestData = a5IngestData
        ingest.videoFormat = videoFormat
        ingest.closedCaptionStatus = closedCaptionStatus
    }

    useEffect(() => {
        const query = encodeURI(`{"terms": {"_id": ["${ingest.id}"]}}`)
        const url = config.apiUrl + `/ingest/items?size=1&query=${query}`
        console.log("Requesting ingests list from: " + url)
        axios.get(url, {headers: {Authorization: auth.token}})
            .then(data => data['data']['data'][0])
            .then(updateA5IngestData)
    }, [])

    return ingest.a5IngestData ? <Grid columns={1} centered>
        <Grid.Row>
            <Grid.Column>
                <Grid columns={2}>
                    { NonEditableField("Asset Status", ingest.a5IngestData['status']) }
                    { NonEditableField("Clock Number", ingest.a5IngestData['asset']['unique']) }
                    { NonEditableField("Order Reference", ingest.a5IngestData['order']['reference']) }
                    { NonEditableField("Agency", ingest.a5IngestData['agency']['name']) }
                    { NonEditableField("Advertiser", ingest.a5IngestData['asset']['advertiser']) }
                    { NonEditableField("Product", ingest.a5IngestData['asset']['product']) }
                    { NonEditableField("Title", ingest.a5IngestData['asset']['title']) }
                    { NonEditableField("First Air Date", ingest.a5IngestData['asset']['firstAirDate']) }
                    { NonEditableField("Market", ingest.a5IngestData['order']['market']['name']) }
                    { NonEditableField("Duration", ingest.a5IngestData['asset']['duration']) }
                    { NonEditableField("SpecId", ingest.a5IngestData['asset']['revision']['specDbDocID']) }
                </Grid>
            </Grid.Column>
        </Grid.Row>
    </Grid> : <div> Loading... </div>

}