import React, {useContext, useEffect, useState} from "react";
import {IngestDocContext} from "../context/IngestDocument";
import {
    FrameRate,
    NoTimeCode,
    parseTc,
    tcToFrames,
    tcToString,
    TimeCode
} from "../model/TimeCode";
import {Dropdown, Form, FormGroup, Grid} from "semantic-ui-react";
import Cleave from "cleave.js/react";

type AdTimecodes = {
    adDurationRaw: string,
    adDuration: TimeCode,
    fullDurationRaw: string,
    fullDuration: TimeCode,
    firstActiveFrameRaw: string
    firstActiveFrame: TimeCode,
    frameRate: string
}

const InitAdTimecodes = {
    adDurationRaw: "",
    adDuration: NoTimeCode,
    fullDurationRaw: "",
    fullDuration: NoTimeCode,
    firstActiveFrameRaw: "",
    firstActiveFrame: NoTimeCode,
    frameRate: "25"
}

export const DurationInput = () => {

    const {ingest, setIngest} = useContext(IngestDocContext)
    const [tc, setTc] = useState<AdTimecodes>(InitAdTimecodes)

    function handleAdDuration(evt: any) {
        const value = evt.target.value
        const parsed = parseTc(evt.target.value)
        if (parsed != NoTimeCode)
            setTc({...tc, adDurationRaw: value, adDuration: parsed})
        else
            setTc({...tc, adDurationRaw: tc.adDurationRaw + ":"})
    }

    function handleFullDuration(evt: any) {
        const value = evt.target.value
        const parsed = parseTc(evt.target.value)
        if (parsed != NoTimeCode)
            setTc({...tc, fullDurationRaw: value, fullDuration: parsed})
        else
            setTc({...tc, fullDurationRaw: tc.adDurationRaw + ":"})
    }

    function handleFaF(evt: any) {
        const value = evt.target.value
        const parsed = parseTc(evt.target.value)
        if (parsed != NoTimeCode)
            setTc({...tc, firstActiveFrameRaw: value, firstActiveFrame: parsed})
        else
            setTc({...tc, firstActiveFrameRaw: tc.adDurationRaw + ":"})
    }

    useEffect(() => {
        recalculateFrames()
    }, [tc])

    const updateFps = (e: React.SyntheticEvent<HTMLElement>, data: any) => {
        setTc({...tc, frameRate: data.value})
    }

    const recalculateFrames = () => {
        const adDurationInFrames = tcToFrames(tc.adDuration, frameRateFromSelection(tc.frameRate));
        const fullDurationInFrames = tcToFrames(tc.fullDuration, frameRateFromSelection(tc.frameRate));
        const firstActiveFrame = tcToFrames(tc.firstActiveFrame, frameRateFromSelection(tc.frameRate));
        const duration = {adDurationInFrames, fullDurationInFrames, firstActiveFrame}
        setIngest({...ingest, duration})
    }

    function frameRateFromSelection(s: string): FrameRate {
        const arr = s.split(" ")
        return {fps: parseFloat(arr[0]), dropFrame: arr[1] == "DF"}
    }

    const fpsSelection = () => {
        const entry = (v: string) => {
            return {key: v, text: v, value: v}
        };
        const options = ["23.98", "25", "29.97 NDF", "29.97 DF"].map(entry);
        return <Dropdown
            fluid
            selection
            options={options}
            value={tc.frameRate}
            onChange={updateFps}
        />
    }

    return <div>
        <Grid columns={3} centered>
            <Grid.Row>
                <Grid.Column> Frame rate: </Grid.Column>
                <Grid.Column> {fpsSelection()} </Grid.Column>
            </Grid.Row>
        </Grid>

        <table width={"100%"} className={"with-margin"} cellPadding={"5px"}>
            <tr>
                <td><label> Ad duration </label></td>
                <td><Cleave
                    value={tc.adDurationRaw}
                    onChange={handleAdDuration}
                    options={{
                        delimiter: ":",
                        numericOnly: true,
                        blocks: [2, 2, 2, 5]
                    }}
                /></td>
                <td align="left" className={"with-margin"}><label> {tcToString(tc.adDuration)} </label></td>
                <td><label> Frames: {ingest.duration.adDurationInFrames} </label></td>
            </tr>

            <tr>
                <td><label> Full duration </label></td>
                <td><Cleave
                    value={tc.fullDurationRaw}
                    onChange={handleFullDuration}
                    options={{
                        delimiter: ":",
                        numericOnly: true,
                        blocks: [2, 2, 2, 5]
                    }}
                /></td>
                <td align="left" className={"with-margin"}><label> {tcToString(tc.fullDuration)} </label></td>
                <td><label> Frames: {ingest.duration.fullDurationInFrames} </label></td>
            </tr>

            <tr>
                <td><label> First active frame </label></td>
                <td><Cleave
                    value={tc.firstActiveFrameRaw}
                    onChange={handleFaF}
                    options={{
                        delimiter: ":",
                        numericOnly: true,
                        blocks: [2, 2, 2, 5]
                    }}
                /></td>
                <td align="left" className={"with-margin"}><label> {tcToString(tc.firstActiveFrame)} </label></td>
                <td><label> Frames: {ingest.duration.firstActiveFrame} </label></td>
            </tr>
        </table>

    </div>

}