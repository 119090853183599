import React, {useContext, useEffect} from "react";
import {Grid, FormGroup, Dropdown, Table} from 'semantic-ui-react'
import config from "../config";
import axios from "axios";
import {AudioTrack, Dictionaries, Duration, IngestDocContext} from "../context/IngestDocument";
import {AuthContext} from "../context/AuthContext";
import {DurationInput} from "./DurationInput";


export const IngestEditForm = () => {
    const {auth, setAuth} = useContext(AuthContext);
    const {ingest, setIngest} = useContext(IngestDocContext)

    function updateDictionaries(d: Dictionaries) {
        setIngest({...ingest, dictionaries: d})
    }

    function updateClosedCaptions(e: React.SyntheticEvent<HTMLElement>, data: any) {
        setIngest({...ingest, closedCaptionStatus: data.value})
    }

    function updateVideoFormat(e: React.SyntheticEvent<HTMLElement>, data: any) {
        setIngest({...ingest, videoFormat: data.value})
    }

    function loadOne(name: string) {
        const url = config.apiUrl + `/ingest/dictionary/${name}`
        console.debug("Loading dictionary: " + url)
        return axios
            .get(url, {headers: {Authorization: auth.token}})
            .then(s => s.data['data'])
            .catch(err => {
                console.error(`Cannot load ${name} dictionary from ${url}: ${err.message}`);
                return Promise.reject(`dictionary ${name} - failed`)
            })
    }

    function extractValues(dictionary: any, depth: number, current: number = 0): Array<any> {
        if (current >= depth)
            return dictionary['values']
        else {
            if (dictionary && dictionary['values'] && dictionary['values'][0])
                return extractValues(dictionary['values'][0], depth, current + 1)
            else {
                console.warn("Cannot extract values from " + JSON.stringify(dictionary))
                return []
            }
        }
    }

    const audioSpecOptions = ingest.dictionaries ? extractValues(ingest.dictionaries?.audio, 2).map((v: any) => {
        return {key: v['key'], text: v['name'], value: v['key']}
    }) : []

    const langOptions = ingest.dictionaries ? extractValues(ingest.dictionaries?.languages, 0).map((v: any) => {
        return {key: v['id'], text: v['name'], value: v['id'], langid: v['key']}
    }) : []

    const videoSpecOptions = ingest.dictionaries ? extractValues(ingest.dictionaries?.formats, 1).map((v: any) => {
        return {key: v['key'], text: v['name'], value: v['key']}
    }) : []

    function closedCaptionSelection() {
        function entry(v: string) {
            return {key: v, text: v, value: v}
        }
        const options = [entry("No"), entry("Yes"), entry("Supplied"), entry("Awaiting")];
        return <Dropdown
            fluid
            selection
            options={options}
            value={ingest.closedCaptionStatus}
            onChange={updateClosedCaptions}
        />
    }

    function videoFormatSelection() {
        return <Dropdown
            fluid
            selection
            options={videoSpecOptions}
            value={ingest.videoFormat}
            onClick={loadDictionaries}
            onChange={updateVideoFormat}
        />
    }

    function updateAudioSpec(e: React.SyntheticEvent<HTMLElement>, data: any, index: number) {
        const updatedTracks = ingest.audioTracks
        updatedTracks[index].specId = data.value
        setIngest({...ingest, audioTracks: updatedTracks})
    }

    function updateLanguage(e: React.SyntheticEvent<HTMLElement>, data: any, index: number) {
        const updatedTracks = ingest.audioTracks
        updatedTracks[index].keyId = data.value
        updatedTracks[index].langId = langOptions.find(v => v.value === data.value)?.langid
        setIngest({...ingest, audioTracks: updatedTracks})
    }

    function audioTrackSelection() {
        return <div>
            {ingest.audioTracks.map((track: AudioTrack, i: number) => {
                return <Table key={i}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={1}>
                                <label> {i} </label>
                            </Table.Cell>
                            <Table.Cell width={7}>
                                <Dropdown
                                    fluid
                                    selection
                                    search
                                    options={audioSpecOptions}
                                    value={ingest.audioTracks[i].specId}
                                    onChange={(e, v) => updateAudioSpec(e, v, i)}
                                />
                            </Table.Cell>
                            <Table.Cell width={7}>
                                <Dropdown
                                    fluid
                                    selection
                                    search
                                    options={langOptions}
                                    value={ingest.audioTracks[i].keyId}
                                    onChange={(e, v) => updateLanguage(e, v, i)}
                                />
                            </Table.Cell>
                            <Table.Cell width={1}>
                                <button style={{color: "red"}} onClick={() => removeAudioTrack(i)}> X</button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            })}

            <button onClick={() => addAudioTrack()}> Add</button>
        </div>
    }

    function removeAudioTrack(i: number) {
        const audioTracks = ingest.audioTracks
        audioTracks.splice(i, 1)
        setIngest({...ingest, audioTracks})
    }

    function addAudioTrack() {
        loadDictionaries()
        const audioTracks = ingest.audioTracks
        audioTracks.push({
            langId: "",
            keyId: -1,
            specId: ""
        })
        setIngest({...ingest, audioTracks})
    }

    function resetFormat() {
        const videoFormat = ingest.a5IngestData ? ingest.a5IngestData['asset']['revision']['specDbDocID'] : ""
        setIngest({...ingest, videoFormat})
    }

    function resetClosedCaptions() {
        const closedCaptionStatus = ingest.a5IngestData ? ingest.a5IngestData['asset']['closedCaptionStatus']: "No"
        setIngest({...ingest, closedCaptionStatus})
    }

    function loadDictionaries() {
        if (! ingest.dictionaries) {
            const p = ["audio", "langs", "formats", "sla"].map(loadOne)
            Promise.all(p)
                .then(values => {
                    const dictionaries = {audio: values[0], languages: values[1], formats: values[2], sla: values[3]}
                    updateDictionaries(dictionaries)
                })
                .catch(err => console.error(`Cannot load dictionaries: ${err.message}`))
        }
    }

    useEffect(() => {
        loadDictionaries()
    }, [])

    return <Grid columns={1} centered>
        <Grid.Row>
            <Grid.Column>
                <FormGroup className="with-border">
                    <label> Video format </label>
                    { videoFormatSelection() }
                    <button  className="with-margin" onClick={() => resetFormat()}> Reset to asset value </button>
                </FormGroup>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <FormGroup className="with-border">
                    <label> Closed captions </label>
                    { closedCaptionSelection() }
                    <button className="with-margin" onClick={() => resetClosedCaptions()}> Reset to asset value </button>
                </FormGroup>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <DurationInput/>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <label> Audio tracks </label>
                { audioTrackSelection() }
            </Grid.Column>
        </Grid.Row>
    </Grid>

}