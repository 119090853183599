export interface AuthConfig {
    authApiUrl: string;
    clientId: string;
    kcLogoutUrl: string;
}

interface Pagination {
    defaultSize: number;
}

interface Config {
    basePath: string;
    apiUrl: string;
    auth: AuthConfig;
    pagination: Pagination;
    sessionIdleTime: number;
    tokenRefreshTime: number;
    a5ApiUserId: string;
}

const pagination: Pagination = {
    defaultSize: 50,
};

const configProd: Readonly<Config> = {
    basePath: '/',
    apiUrl: 'https://twiysinbt7.execute-api.eu-west-1.amazonaws.com/live',
    auth: {
        authApiUrl: 'https://mm-dr.auth.eu-west-1.amazoncognito.com',
        clientId: '16iqqfanueti68iegfgm15i7hr',
        kcLogoutUrl: 'https://identity.adstream.com/auth/realms/Platform/protocol/openid-connect/logout'
    },
    pagination,
    sessionIdleTime: 1000 * 60 * 60, // 60 mins
    tokenRefreshTime: 1000 * 60 * 30, // 30 mins
    a5ApiUserId: "4ef31ce1766ec96769b399c0"
};

const configUat: Readonly<Config> = {
    basePath: '/',
    apiUrl: 'https://07yil8alv2.execute-api.eu-west-1.amazonaws.com/uat',
    auth: {
        authApiUrl: 'https://uatingest.auth.eu-west-1.amazoncognito.com',
        clientId: '3e18962pdsian1bj3bt6k1d7hb',
        kcLogoutUrl: 'https://auth.adstreamdev.com/auth/realms/experiment/protocol/openid-connect/logout'
    },
    pagination,
    sessionIdleTime: 1000 * 60 * 60, // 60 mins
    tokenRefreshTime: 1000 * 60 * 30, // 30 mins
    a5ApiUserId: "4ef31ce1766ec96769b399c0"
};

const config = process.env.REACT_APP_STAGE == 'prod' ? configProd : configUat;

export default config;
