import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {AuthContext, getToken, NoAuth, persistAuth, serverLogout} from "../context/AuthContext";


export const LogoutView = () => {

    const {auth, setAuth} = useContext(AuthContext)
    const history = useHistory();

    useEffect( () =>  {
        handleLogout();
    }, [])

    function handleLogout() {
        setAuth(NoAuth)
        localStorage.removeItem("auth")
        serverLogout();
    }

    return <div> Logged out... </div>;
}