import * as React from 'react'

export type Dictionaries = {
    audio: any,
    languages: any,
    formats: any,
    sla: any
}

export type Duration = {
    adDurationInFrames: number,
    fullDurationInFrames: number,
    firstActiveFrame: number
}

export type AudioTrack = {
    specId: string,
    keyId: number,
    langId: string
}

export type IngestDocumentData = {
    id: string,
    a5IngestData: any,
    duration: Duration,
    audioTracks: Array<AudioTrack>,
    closedCaptionStatus: string,
    videoFormat: string,
    file: File | null,
    dictionaries: Dictionaries | null,
    statusMessage: string | null
}

function initIngestDocument(id: string): IngestDocumentData {
    return {
        id: id,
        a5IngestData: null,
        duration: {
            adDurationInFrames: 0,
            fullDurationInFrames: 0,
            firstActiveFrame: 0
        },
        audioTracks: [],
        closedCaptionStatus: "No",
        videoFormat: "",
        file: null,
        dictionaries: null,
        statusMessage: null
    }
}


export const IngestDocContext = React.createContext(
    {
        ingest: initIngestDocument("undefined"),
        setIngest: (doc: IngestDocumentData) => {}
    }
);

export {initIngestDocument};
