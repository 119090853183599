import './App.css';
import {IngestListView} from "./components/IngestListView";
import 'semantic-ui-css/semantic.min.css';
import IdleTimer from 'react-idle-timer';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {IngestView} from "./components/IngestView";
import {LoginView} from "./components/LoginView";
import {useState} from "react";
import {Auth, NoAuth, AuthContext, persistAuth, initAuth} from "./context/AuthContext";
import config from "./config";
import axios from "axios";
import {LogoutView} from "./components/LogoutView";
import {ItemPath, ItemsPath, LogoutPath, RootPath} from "./RouterPath";


type AppStatus = {
    auth: Auth,
    initialized: boolean
}

function App() {

    const [appStatus, setAppStatus] = useState<AppStatus>({auth: NoAuth, initialized: false})
    const auth = appStatus.auth
    const setAuth = (auth: Auth) => setAppStatus({auth, initialized: true})

    if (!appStatus.initialized) {
        initAuth()
            .then(setAuth)
            .catch(function (error) {
                setAuth(NoAuth)
            })
    }

    function refreshAuth() {
        if (!auth.token) return;

        console.debug("Refreshing auth token...")
        const url = config.apiUrl + "/ingest/refreshSession"
        axios
            .post(url, auth, {headers: {Authorization: auth.token}})
            .then(r => persistAuth(r.data.data, setAuth))
            .catch(function (error) {
                if (error.response) {
                    console.warn("Failed to refresh session.")
                } else if (error.request) {
                    console.log("No response received for session refresh request.");
                } else {
                    console.log('Session refresh failed: ', error.message);
                }
            })
    }

    function logout() {
        if (!auth.token) return;

        setAuth(NoAuth)
        localStorage.removeItem("auth")
        alert("Session expired.")
    }

    if (! appStatus.initialized)
        return <div style={{position: "absolute", right: "50%", top: "50%"}}> <h3> Loading... </h3> </div>
    else return (
      <AuthContext.Provider value={{auth, setAuth}}>
        <div className="App">
            <header className="App-header">
                Web Ingest App v1.0
            </header>
            <IdleTimer
                onIdle={logout}
                onAction={refreshAuth}
                throttle={config.tokenRefreshTime}
                timeout={config.sessionIdleTime}
            >
            </IdleTimer>
            <BrowserRouter>
                <Switch>
                    <Route exact path={LogoutPath}>
                        <LogoutView/>
                    </Route>
                    <Route exact path={ItemPath}>
                        <IngestView/>
                    </Route>
                    <Route exact path={ItemsPath}>
                        <IngestListView/>
                    </Route>
                    <Route exact path={RootPath}>
                        <LoginView/>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
      </AuthContext.Provider>
    );
}

export default App;
