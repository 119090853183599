import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {AuthContext, checkAuth, getToken, persistAuth} from "../context/AuthContext";
import {ItemsPath} from "../RouterPath";


export const LoginView = () => {

    const {auth, setAuth} = useContext(AuthContext)
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);

    const gotoList = () => history.push(ItemsPath);

    useEffect( () =>  {
        handleCodeRedirect();
    }, [])

    function handleCodeRedirect() {
        const code = query.get("code");
        // User is authenticated already
        if (auth.token)
            gotoList();
        // IDP redirect
        else if (code) {
            getToken(code)
                .then((r: any) => persistAuth(r.data.data, setAuth))
                .then(() => gotoList())
        }
        // Start authentication flow
        else
            checkAuth(auth);
    }

    return <div> Loading... </div>;
}