import * as React from 'react'
import config from "../config";
import axios from "axios";
import * as crypto from 'crypto';

export type Auth = {
    tokenDigest: string | undefined,
    token: string | undefined,
    accessToken: string | undefined,
    refreshToken: string | undefined,
    timestamp: number | undefined
}

export const NoAuth: Auth = {
    tokenDigest: undefined,
    token: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    timestamp: undefined
}

export function UsernameAuth(username: string): Auth {
    return {
        tokenDigest: username,
        token: undefined,
        accessToken: undefined,
        refreshToken: undefined,
        timestamp: undefined
    };
}

export const AuthContext = React.createContext(
    {
        auth: NoAuth,
        setAuth: (doc: Auth) => {}
    }
);

function saveAuth(auth: Auth) {
    const url = config.apiUrl + "/ingest/auth"

    axios
        .put(url, auth, {headers: {Authorization: auth.token}})
        .then(() => {
            console.debug("Auth token persisted successfully.")
        })
        .catch(function (error) {
            if (error.response) {
                console.warn("Failed to persist auth token:  " + JSON.stringify(error.response), error.message);
            } else if (error.request) {
                console.warn("No response received while persisting auth token.");
            } else {
                console.warn('Failed to persist auth token: ', error.message);
            }
        })
}

function loadAuth(token: string): Promise<Auth> {
    const url = config.apiUrl + "/ingest/auth/find"
    return axios
        .post(url, {token: token}, {headers: {Authorization: token}})
        .then(r => {
            console.debug("Auth token retrieved successfully.")
            return {
                tokenDigest: digest(token),
                token: token,
                accessToken: r.data.accessToken,
                refreshToken: r.data.refreshToken,
                timestamp: new Date().getTime()
            };
        })
        .catch(function (error) {
            if (error.response) {
                console.warn("Failed to retrieve auth token: " + JSON.stringify(error.response), error.message);
                return Promise.reject("Failed to retrieve auth token")
            } else if (error.request) {
                console.warn("No response received while retrieving auth token.");
                return  Promise.reject("No response")
            } else {
                console.warn('Failed to retrieve auth token: ', error.message);
                return Promise.reject("Failed to retrieve auth token")
            }
        })
}

function digest(str: string) {
    return crypto
        .createHash("md5")
        .update(str)
        .digest("hex");
}

export function persistAuth(
    responseData: any,
    setAuth: any
): boolean {
    if (!responseData) {
        console.error("Cannot parse token response.");
        return false;
    }
    const token = responseData.id_token;
    if (token) {
        const tokenDigest = digest(token);

        const authObject = {
            tokenDigest,
            token,
            accessToken: responseData.access_token,
            refreshToken: responseData.refresh_token,
            timestamp: new Date().getTime()
        }
        // set in-memory state
        setAuth(authObject)
        // persist to dynamo
        saveAuth(authObject)

        const localAuth = {
            token: authObject.token,
            timestamp: new Date().getTime()
        }
        localStorage.setItem("auth", JSON.stringify(localAuth))
        console.log(`Auth persisted for user ${tokenDigest}, token [${token}]`)

        return true;
    } else {
        console.warn(`Error persisting token ${token}`)
        return false;
    }
}

export function initAuth(): Promise<Auth> {
    const tokenStr = localStorage.getItem("auth")
    if (tokenStr) {
        const localAuthObject = JSON.parse(tokenStr)
        if (new Date().getTime() - localAuthObject.timestamp < config.sessionIdleTime) {
            return loadAuth(localAuthObject.token)
        }
        else return Promise.reject("Token not valid.")
    }
    else return Promise.reject("Token not found.")
}

const wl = window.location;
const redirectUri = `${wl.protocol}//${wl.host}`;

export function checkAuth(auth: Auth) {
    if (!auth.token) {
        const params = `client_id=${config.auth.clientId}&redirect_uri=${redirectUri}&scope=openid+profile&response_type=code`
        const loginUrl = `${config.auth.authApiUrl}/login?${params}`;
        console.log(`Redirecting to ${loginUrl}`);
        window.location.assign(loginUrl);
        return false;
    } else
        return true;
}

export async function getToken(code: string) {
    const body = {
        code,
        redirectUri,
        clientId: config.auth.clientId
    }

    const getTokenUri = `${config.apiUrl}/ingest/oauth2/token`;

    return axios
        .post(getTokenUri, body)
        .catch((err) => {
            const serverError = (err.response && err.response.data) ? err.response.data.data : {};
            const errorMsg = Object.keys(serverError).length === 0 ? err.message : JSON.stringify(err.response.data.data);
            const alertMessage = "Login failed: " + errorMsg;
            console.error(`Login error: ${err.message}, response ${JSON.stringify(err.response)}`, err);
            alert(alertMessage);
            serverLogout();
        });
}

export function serverLogout() {
    // TODO: logout from Cognito leaves KC cookies, so we need to logout from KC instead.
    const params = `client_id=${config.auth.clientId}&scope=openid+profile&redirect_uri=${redirectUri}&response_type=code`
    window.open(`${config.auth.authApiUrl}/logout?${params}`);
    window.location.assign(config.auth.kcLogoutUrl);
}