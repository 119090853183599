export type TimeCode = {
    hours: number,
    minutes: number,
    seconds: number,
    frames: number
}

export const tcToString = (t: TimeCode) => {
    return  t.hours + "h " + t.minutes + "m " + t.seconds + "s " + t.frames + "f";
}

export const NoTimeCode = {
    hours: 0, minutes: 0, seconds: 0, frames: 0
}

export type FrameRate = {
    fps: number,
    dropFrame: boolean
}

export const parseTc = (str: string) => {
    function p(s: string) {
        const v = parseInt(s);
        return v ? v : 0;
    }

    const arr = str.split(":")
    const h = p(arr[0]);
    const m = p(arr[1]);
    const s = p(arr[2]);
    const f = p(arr[3]);

    if (m < 60 && s < 60)
        return {
            hours: h, minutes: m, seconds: s, frames: f
        }
    else
        return NoTimeCode;
}

export const tcToFrames = (tc : TimeCode, fr: FrameRate) => {
    const totalMinutes = 60 * tc.hours + tc.minutes;
    const rfps = Math.round(fr.fps);
    const drops = (600 * (rfps - fr.fps));

    const result = 3600 * rfps * tc.hours + 60 * rfps * tc.minutes +
          rfps * tc.seconds + tc.frames -
         (fr.dropFrame ? (drops / 9) * (totalMinutes - (totalMinutes / 10)) : 0);

    return Math.round(result);
}
